import AppLink from '@theme/components/atom/AppLink'

export default {
  components: {
    AppLink,
  },
  props: {
    menus: {
      type: Array,
      default() {
        return [
          this.$store.state.menu.footer_main_1,
          this.$store.state.menu.footer_main_2,
          this.$store.state.menu.footer_main_3,
          this.$store.state.menu.footer_main_4,
        ]
      },
    },
  },
  data() {
    return {
      opened: false,
    }
  },
  methods: {
    open(groupId) {
      this.opened = groupId
    },
  },
}
